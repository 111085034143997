<template>
  <Authenticator>
    <v-app>
      <NavPanel :mini="miniNav" @minimize="miniNav = $event" />

      <v-app-bar app clipped-left dark color="primary">
        <!-- <v-app-bar-title>PS-Admin</v-app-bar-title> -->
        <v-menu
          offset-y
          rounded
          close-on-click
          close-on-content-click
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text dark v-bind="attrs" v-on="on">
              PS Admin - AiFace Area Marketing
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in [
                {
                  title: 'RepChecker Admin',
                  url: 'https://testadmin.repchecker.jp',
                },
                {
                  title: 'RC Booking Admin',
                  url: 'https://admin.rc-booking.com',
                },
                {
                  title: 'Hotel Master',
                  url: 'https://hotelmaster.psinc.jp/',
                },
              ]"
              :key="index"
              :href="item.url"
              target="_blank"
            >
              <v-list-item-title
                >{{ item.title }}
                <v-icon right small>mdi-open-in-new</v-icon></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-menu
          v-model="showChangePasswordModal"
          left
          bottom
          offset-y
          style="z-index: 999"
          :close-on-click="true"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              text
              data-test="show-account-modal-btn"
              @click="showChangePasswordModal = true"
            >
              <v-icon>mdi-account-circle</v-icon>
              <span class="ml-2">{{ $root.user.email }}</span>
            </v-btn>
          </template>
          <ChangePasswordModal @close="showChangePasswordModal = false" />
        </v-menu>
      </v-app-bar>

      <v-main>
        <header
          style="
            width: 100%;
            position: fixed;
            z-index: 1;
            color: grey;
            height: 60px;
            background: #f7f9f8;
          "
        >
          <v-breadcrumbs :items="breadcrumbs" class="font-weight-bold">
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </v-breadcrumbs>
        </header>

        <router-view
          v-show="!viewLoading"
          style="
            background-color: #f7f9f8;
            padding: 55px 0 0 0;
            min-height: 100vh;
          "
          :miniNav="miniNav"
        />

        <v-snackbar
          v-model="$root.isSaved_"
          light
          :top="true"
          :right="true"
          timeout="2000"
        >
          保存しました
        </v-snackbar>
        <v-snackbar
          v-model="$root.isDeleted_"
          light
          :top="true"
          :right="true"
          timeout="2000"
        >
          削除しました
        </v-snackbar>
        <v-snackbar
          v-model="$root.isNotValidated_"
          light
          :top="true"
          :right="true"
          timeout="3000"
        >
          <span class="red--text"> 未入力箇所があり保存できません。 </span>
        </v-snackbar>
        <v-snackbar
          v-model="$root.isError_"
          light
          :top="true"
          :right="true"
          timeout="3000"
        >
          <span class="red--text">
            入力形式に不適切な箇所があり保存できません。
          </span>
        </v-snackbar>

        <v-overlay :value="viewLoading" absolute>
          <v-progress-circular
            v-if="viewLoading"
            class="mt-auto"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-overlay>
      </v-main>
    </v-app>
  </Authenticator>
</template>

<script>
  import { models } from '@am-area/sdk'
  const { Auth } = models

  import Authenticator from '@/components/Authenticator.vue'
  import NavPanel from '@/components/NavPanel'
  import Routes from '@/datas/routes'
  import FrameModal from '@/components/FrameModal.vue'
  import HeaderOfList from '@/components/HeaderOfList.vue'
  import ChangePasswordModal from '@/components/ChangePasswordModal.vue'

  export default {
    components: {
      Authenticator,
      NavPanel,
      Routes,
      FrameModal,
      HeaderOfList,
      ChangePasswordModal,
    },
    data: () => ({
      miniNav: false,
      showChangePasswordModal: false,
    }),
    computed: {
      user: {
        get() {
          return this.$root.user
        },
        set(user) {
          this.$root.user = user
        },
      },
      breadcrumbs() {
        let last = null
        if (this.$route.path == '/') return [{ text: 'HOME' }]
        return this.$route.path
          .split('/')
          .map((path) => {
            if (!path) return null
            const targets = !!last ? last.children : Routes
            const current = targets.find((o) => {
              let lastIndexOfPath = o.path.split('/')
              return lastIndexOfPath[lastIndexOfPath.length - 1] == path
            })
            last = current
            if (!current) {
              if (path === 'analysis') return { text: 'エリア分析' }
              else if (path === 'issued-tickets')
                return { text: '利用特典一覧・分析' }
              else return { text: '詳細' }
            }
            return {
              text: current.name || null,
            }
          })
          .filter((o) => o)
      },
    },
    async created() {
      await this.getUser()
    },
    methods: {
      async getUser() {
        const user = await Auth.user()
        this.user = user
      },
    },
  }
</script>
