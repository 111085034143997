<template>
  <div>
    <div
      style="
        position: fixed;
        z-index: 1;
        height: 50px;
        background: #f7f9f8;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
      "
    >
      <v-btn
        dark
        color="grey darken-2"
        class="ml-5 font-weight-bold"
        link
        @click="$router.go(-1)"
        >一覧に戻る</v-btn
      >
    </div>
    <v-container class="px-10" style="padding-top: 70px; max-width: 900px">
      <div class="mx-auto">
        <v-container>
          <v-card haped class="mb-8">
            <v-row align="center" justify="space-between" class="mx-3">
              <slot name="title"></slot>
              <v-btn
                v-if="$route.params.id !== 'new' && $route.name != 'User' && $route.name != 'Admin'"
                small
                dark
                outlined
                class="font-weight-bold mr-3"
                color="error"
                @click="$emit('delete')"
                ><v-icon>mdi-delete</v-icon>削除</v-btn
              >
            </v-row>
            <v-container>
              <slot name="main"> </slot>
            </v-container>
            <div class="d-flex justify-end pr-3 pb-10">
              <CreateUpdateButton
                :loading="loading"
                @submit="$emit('submit')"
              />
            </div>
          </v-card>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import CreateUpdateButton from '@/components/CreateUpdateButton.vue'

export default {
  components: {
    CreateUpdateButton,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
}

.message {
  display: none;
}

.validated.null {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 16px;
  color: red;
}

.border {
  border-bottom: 1px solid #002c81;
}
</style>
