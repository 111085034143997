<template>
  <v-menu :disabled="disabled" bottom offset-y class="mx-5">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="blue lighten-5"
        clearable
        v-bind="attrs"
        v-on="on"
        elevation="0"
        style="height: 46px"
      >
        <v-icon>mdi-calendar</v-icon>
        <v-text-field
          :value="date"
          :clearable="isClearable"
          v-bind="attrs"
          v-on="on"
          class="ml-2 text-subtitle-2"
          style="width: 120px"
          :rules="[...rules]"
          @click:clear="date = null"
        ></v-text-field>
      </v-btn>
    </template>
    <v-date-picker
      v-model="date"
      locale="ja"
      :day-format="(date) => new Date(date).getDate()"
      no-title
      color="#002C81"
      :min="min"
      @change="$emit('change')"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    min: {
      type: String,
      default: '',
    },
    isClearable: {
      type: Boolean,
      default: () => false,
    },
    height: {
      type: Number,
    },
    rules: { type: Array, default: () => [] },
  },
  computed: {
    date: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  data() {
    return {
      // pickerDate: '',
    }
  },
}
</script>

<style lang="scss" scoped></style>
