<template>
  <div>
    <HeaderOfList @search="getAdmins()" :miniNav="miniNav" />
    <v-container class="" style="padding-top: 70px; max-width: 1200px">
      <div class="pl-2 pb-2">{{ data.length }}件</div>
      <div class="px-3 py-3" style="background-color: #fff">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">名前</th>
                <th width="80" class="text-left">管理</th>
              </tr>
            </thead>
            <TableLoading v-if="tableLoading" :loading="tableLoading" />
            <tbody v-else>
              <tr v-for="d in data" :key="d.id">
                <td>{{ `${d.family_name} ${d.given_name}` }}</td>
                <td>
                  <v-btn
                    small
                    dark
                    class="mr-3 font-weight-bold"
                    color="#002C81"
                    link
                    :to="`${$route.path}/${d.id}`"
                    >詳細</v-btn
                  >
                </td>
              </tr>
              <tr v-show="!data.length && !tableLoading">
                <td>該当のアカウントはありません。</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
  </div>
</template>

<script>
  import HeaderOfList from '@/components/HeaderOfList.vue'
  import { models } from '@am-area/sdk'
  const { Admin } = models
  export default {
    components: {
      HeaderOfList,
    },

    data() {
      return {
        data: [],
      }
    },
    props: {
      miniNav: { type: Boolean, default: false },
    },

    computed: {
      //
    },
    async created() {
      await this.getAdmins()
    },

    methods: {
      async getAdmins() {
        this.tableLoading = true
        const query = _.cloneDeep(this.$route.query)
        const admins = await Admin.index(query)
        this.data = admins
        this.tableLoading = false
      },
    },
  }
</script>
