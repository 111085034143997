<template>
  <div>
    <HeaderOfList @search="getAreas()" :miniNav="miniNav" />
    <v-container class="" style="padding-top: 60px; max-width: 1200px">
      <div class="pl-2 pb-2">{{ data.length }}件</div>
      <div class="px-3 py-3" style="background-color: #fff">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="80" class="text-left">状態</th>
                <th class="text-left">エリア名</th>
                <th class="text-left">主アカウント</th>
                <th class="text-left">利用期間</th>
                <th width="80" class="text-left">管理</th>
              </tr>
            </thead>
            <TableLoading v-if="tableLoading" :loading="tableLoading" />
            <tbody v-else>
              <tr
                v-for="(d, i) in data"
                :key="i"
                :style="{
                  background: d.is_active ? '' : '#c5c7c4',
                }"
              >
                <td>
                  <v-btn
                    v-if="d.is_active"
                    small
                    color="#66BB6A"
                    class="white--text font-weight-bold pa-4"
                    @click.stop="openModal(d)"
                  >
                    有効
                  </v-btn>
                  <v-btn
                    v-else
                    small
                    color="#E57373"
                    class="white--text font-weight-bold pa-4"
                    @click.stop="openModal(d)"
                  >
                    無効
                  </v-btn>

                  <frame-modal :name="`IsActiveChange${d.id}`">
                    <v-card>
                      <v-card-title>{{
                        modal.isActive ? 'エリアの無効化' : 'エリアの有効化'
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="text-subtitle-1 mt-4 black--text">
                        <div class="text-h5 font-weight-bold mb-1">
                          {{ modal.areaName }}
                        </div>
                        <span>{{
                          modal.isActive ? 'を無効にする' : 'を有効にする'
                        }}</span>
                      </v-card-text>
                      <v-card-actions>
                        <v-col class="text-right">
                          <v-btn text @click="modalClose"> キャンセル </v-btn>
                          <v-btn
                            v-if="modal.isActive"
                            color="#E57373"
                            class="ml-3 font-weight-bold"
                            @click="updateIsActive(d)"
                          >
                            無効にする
                          </v-btn>
                          <v-btn
                            v-else
                            color="#66BB6A"
                            class="ml-3 font-weight-bold"
                            @click="updateIsActive(d)"
                          >
                            有効にする
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </frame-modal>
                </td>
                <td>{{ d.v2_name.ja }}</td>
                <td>
                  <template v-if="d.owner">
                    <router-link :to="`/account/users/${d.owner.id}`">
                      {{ `${d.owner.family_name} ${d.owner.given_name}` }}
                    </router-link>
                  </template>
                </td>
                <td>{{ d.started_on }} - {{ d.ended_on }}</td>
                <td>
                  <v-btn
                    small
                    color="#002C81"
                    dark
                    class="mr-2 font-weight-bold"
                    link
                    :to="`${$route.path}/${d.id}`"
                    >詳細</v-btn
                  >
                </td>
              </tr>
              <tr v-show="!data.length && !tableLoading">
                <td>該当のアカウントはありません。</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { models } from '@am-area/sdk'
  const { Area } = models
  import HeaderOfList from '@/components/HeaderOfList.vue'
  import FrameModal from '@/components/FrameModal.vue'

  export default {
    components: {
      HeaderOfList,
      FrameModal,
    },
    data() {
      return {
        data: {},
        modal: {
          isActive: true,
          areaName: '',
        },
      }
    },
    props: {
      miniNav: { type: Boolean, default: false },
    },
    watch: {
      //
    },
    computed: {
      //
    },
    async created() {
      await this.getAreas()
    },

    methods: {
      async getAreas() {
        this.tableLoading = true
        let query = _.cloneDeep(this.$route.query)
        query.with = 'owner'
        const areas = await Area.index(query)
        this.data = areas
        this.tableLoading = false
      },
      openModal(d) {
        this.modal.isActive = d.is_active
        this.modal.areaName = d.v2_name.ja
        this.modalShow(`IsActiveChange${d.id}`)
      },
      async updateIsActive(data) {
        data.is_active = !data.is_active
        await Area.update(data.id, data)
        await this.modalClose()
        await this.getAreas()
      },
    },
  }
</script>
