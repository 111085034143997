import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Accounts from '../views/Home/Accounts.vue'
import Users from '../views/Home/Accounts/Users.vue'
import User from '../views/Home/Accounts/Users/User.vue'
import Areas from '../views/Home/Accounts/Areas.vue'
import Area from '../views/Home/Accounts/Areas/Area.vue'
import Hotels from '../views/Home/Accounts/Hotels.vue'
import Hotel from '../views/Home/Accounts/Hotels/Hotel.vue'
import Shops from '../views/Home/Accounts/Shops.vue'
import Shop from '../views/Home/Accounts/Shops/Shop.vue'
import Admins from '../views/Home/Admins.vue'
import Admin from '../views/Home/Admins/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [],
  },
  {
    path: '/account',
    component: Accounts,
    children: [
      {
        path: '',
        redirect: 'users',
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'users/:id',
        name: 'User',
        component: User,
      },
      {
        path: 'areas',
        name: 'Areas',
        component: Areas,
      },
      {
        path: 'areas/:id',
        name: 'Area',
        component: Area,
      },
      {
        path: 'hotels',
        name: 'Hotels',
        component: Hotels,
      },
      {
        path: 'hotels/:id',
        name: 'Hotel',
        component: Hotel,
      },
      {
        path: 'shops',
        name: 'Shops',
        component: Shops,
      },
      {
        path: 'shops/:id',
        name: 'Shop',
        component: Shop,
      },
    ],
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins,
  },
  {
    path: '/admins/:id',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/Home/Documents.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
