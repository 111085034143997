export default {
  install(vue) {
    console.log("Installing Main plugin..");
    vue.mixin({
      data() {
        return {
          dataReady: false,
          forgetPass: {
            email: "",
          },
        };
      },
      computed: {
        axiosError: {
          get() {
            return this.$root.axiosError_;
          },
          set(val) {
            this.$root.axiosError_ = val;
          },
        },
        viewLoading: {
          get: function () {
            return this.$root.viewLoading_;
          },
          set: function (val) {
            this.$root.viewLoading_ = val;
          },
        },
        tableLoading: {
          get: function () {
            return this.$root.tableLoading_;
          },
          set: function (val) {
            this.$root.tableLoading_ = val;
          },
        },
        pathToListPage() {
          const p = this.$route.path.split("/").slice(0, -1).join("/");
          return p;
        },
      },
      methods: {
        trimParams(params) {
          const query = {};
          Object.keys(params).map((key) => {
            if (params[key] !== null && params[key] !== "")
              query[key] = params[key];
          });
          return query;
        }
      },
      filters: {
        fromtimestamp(val) {
          return window.moment.unix(val).format("YYYY/MM/DD mm:ss");
        }
      }
    });
  }
};
