<template>
  <v-container>
    <div class="d-flex align-center">
      <v-text-field
        v-model="keyword"
        label="施設名"
        dense
        outlined
        hide-details="auto"
        v-on:keydown.enter.prevent="beforeSerch"
      ></v-text-field>
      <v-btn @click="serch" :loading="loading" class="ml-3"
        >検索</v-btn
      >
    </div>
    <v-list v-if="hotels.length" dense>
      <div class="d-flex align-center justify-space-between mb-5">
        <v-subheader>検索結果</v-subheader>
        <v-btn
          v-if="selectedItem !== null && selectedItem !== undefined"
          @click="add"
          right
          color="#002C81"
          dark
          :loading="loadingSave"
          >追加<v-icon class="ml-2">mdi-check-circle</v-icon></v-btn
        >
      </div>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item v-for="hotel in hotels" :key="hotel.seq">
          <v-list-item-content>
            <v-list-item-title v-text="hotel.keyword"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    hotelName: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      hotels: [],
      keyword: '',
      selectedItem: null,
      loading: false,
      loadingSave: false,
    }
  },
  created() {
    this.setHotelName()
  },
  watch: {
    hotelName: 'setHotelName',
  },
  computed: {},
  methods: {
    beforeSerch(event) {
      if (event.keyCode !== 13) return
      this.serch()
    },
    async serch() {
      if (!this.keyword) return
      this.loading = true
      const payload = {
        keyword: this.keyword,
        region: '',
        prefecture: '',
        keyword_mst_seq: '',
        luxury: '',
        room_count_form: '',
        room_count_to: '',
        area_ids: '',
        ps_hotel_genre: '',
        hotel_brand: '',
        hotel_chain: '',
        hotel_status: '',
      }
      await axios
        .post(
          'https://rc.repchecker.jp/api/hotel-master/search-hotel',
          payload,
          {
            headers: {
              Authorization: 'Bearer asdfasdf',
            },
          }
        )
        .then((res) => (this.hotels = res.data || []))
        .catch((e) => console.log(e))
      if (!this.hotels.length) {
        this.hotels.push({
          keyword: '該当の施設が見つかりませんでした。',
          seq: 0,
        })
      }
      this.loading = false
    },
    async add() {
      await this.$emit('selectHotel', this.hotels[this.selectedItem])
    },
    setHotelName() {
      this.keyword = this.hotelName
    },
  },
  beforeDestroy() {
    this.hotels = []
    this.keyword = ''
  },
}
</script>

<style lang="scss" scoped></style>
