<template>
  <v-form
    ref="form"
    v-model="form.valid"
    :disabled="form.loading"
    lazy-validation
    @submit.prevent="submit()"
  >
    <v-card class="elevation-6 mx-auto" outlined>
      <v-card-text>
        <div class="font-weight-bold">パスワード変更</div>
        <div class="text-caption mb-6">
          ※8文字以上で、大文字、小文字、数字、シンボル("&amp;@#$%!*)1つを含めて設定してください。
        </div>
        <v-text-field
          v-model="data.current_password"
          label="現在のパスワード"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[formRules.required]"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          v-model="data.new_password"
          label="新しいパスワード"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[formRules.required, formRules.password]"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          v-model="data.new_password_confirm"
          label="新しいパスワード（確認用）"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            formRules.required,
            (v) => v === data.new_password || 'パスワードが一致しません。',
          ]"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            type="submit"
            color="primary"
            class="font-weight-bold"
            :disabled="!form.valid"
            :loading="form.loading"
          >
            変更する</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/Form.js";

export default {
  mixins: [FormMixin],
  components: {},
  data: () => ({
    showPassword: false,
    data: {
      current_password: "",
      new_password: "",
      new_password_confirm: "",
    },
  }),
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.form.loading = true;

      // 堀田さんお願いします

      this.form.loading = false;
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
};
</script>
