<template>
  <div>
    <div class="border"></div>
    <template v-if="selectUsers.length">
      <v-simple-table class="mt-10">
        <template v-slot:default>
          <thead>
            <tr>
              <td width="50%">
                <p class="font-weight-bold" style="color: #002c81">
                  主アカウントの紐付け
                </p>
              </td>
            </tr>
          </thead>
        </template>
      </v-simple-table>
      <div class="mb-10">
        <v-autocomplete
          v-model="data.owner_id"
          :items="selectUsers"
          hide-details="auto"
          outlined
          label="アカウント(検索可能)"
          full-width
          color="blue"
          item-color="blue"
          background-color="blue lighten-5"
          dense
          class="px-4"
        ></v-autocomplete>
      </div>
    </template>
    <template v-if="selectAreas.length">
      <v-simple-table class="mt-10">
        <template v-slot:default>
          <thead>
            <tr>
              <td width="50%">
                <p class="font-weight-bold" style="color: #002c81">
                  エリアの紐付け（クーポン対象エリア）
                </p>
              </td>
            </tr>
          </thead>
        </template>
      </v-simple-table>
      <div class="mb-10">
        <!-- Userは複数選択可能 -->
        <template v-if="user">
          <v-autocomplete
            v-model="areasSelected"
            :items="selectAreas"
            item-text="text"
            label="エリア(検索可能)"
            hide-details="auto"
            outlined
            multiple
            menu-props="auto"
            full-width
            color="blue"
            item-color="blue"
            background-color="blue lighten-5"
            dense
            class="px-4"
          ></v-autocomplete>
        </template>
        <!-- Shop -->
        <template v-if="$route.params.id !== 'new' && shop">
          <v-autocomplete
            v-model="areaSelected"
            :items="selectAreas"
            item-text="text"
            label="エリア(検索可能)"
            hide-details="auto"
            outlined
            menu-props="auto"
            full-width
            color="blue"
            item-color="blue"
            background-color="blue lighten-5"
            dense
            class="px-4"
          ></v-autocomplete>
        </template>
        <!-- Hotel -->
        <template
          class="mb-10"
          v-if="($route.params.id === 'new' || hotel) && !user"
        >
          <v-autocomplete
            v-model="data.area_id"
            :items="selectAreas"
            item-text="text"
            label="エリア(検索可能)"
            hide-details="auto"
            outlined
            menu-props="auto"
            full-width
            color="blue"
            item-color="blue"
            background-color="blue lighten-5"
            dense
            class="px-4"
          ></v-autocomplete>
        </template>
      </div>
    </template>
    <template v-if="selectHotels.length">
      <v-simple-table class="mt-10">
        <template v-slot:default>
          <thead>
            <tr>
              <td width="50%">
                <p class="font-weight-bold" style="color: #002c81">
                  施設の紐付け（複数選択可能）
                </p>
              </td>
            </tr>
          </thead>
        </template>
      </v-simple-table>
      <div class="mb-10">
        <v-autocomplete
          v-model="hotelsSelected"
          :items="selectHotels"
          item-text="text"
          label="施設(検索可能)"
          hide-details="auto"
          outlined
          multiple
          menu-props="auto"
          full-width
          color="blue"
          item-color="blue"
          background-color="blue lighten-5"
          dense
          class="px-4"
        ></v-autocomplete>
      </div>
    </template>
    <template v-if="selectShops.length">
      <v-simple-table class="mt-10">
        <template v-slot:default>
          <thead>
            <tr>
              <td width="50%">
                <p class="font-weight-bold" style="color: #002c81">
                  店舗の紐付け（複数選択可能）
                </p>
              </td>
            </tr>
          </thead>
        </template>
      </v-simple-table>
      <div class="mb-10">
        <v-autocomplete
          v-model="shopsSelected"
          :items="selectShops"
          item-text="text"
          label="店舗(検索可能)"
          hide-details="auto"
          outlined
          multiple
          menu-props="auto"
          full-width
          color="blue"
          item-color="blue"
          background-color="blue lighten-5"
          dense
          class="px-4"
        ></v-autocomplete>
      </div>
    </template>
    <div v-if="$route.params.id !== 'new'">
      <div class="border"></div>
      <div
        class="font-weight-bold text-subtitle-2 mx-4 mt-10 mb-5"
        style="color: #002c81"
      >
        紐付け一覧（クリックすると詳細ページに移動します）
      </div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-if="selectUsers.length">
              <td width="30%" class="my-1">主アカウント</td>
              <td>
                <div v-if="data.owner_id" class="mx-3">
                  <router-link :to="`/account/users/${data.owner_id}`">
                    {{ userName(data.owner_id) }}
                  </router-link>
                </div>
                <div v-else class="mx-3">なし</div>
              </td>
            </tr>
            <tr v-if="selectUsers.length">
              <td width="30%">アカウント</td>
              <td>
                <div v-if="data.users && data.users.length" class="mx-3 my-2">
                  <div
                    v-for="(user, i) in data.users"
                    :key="i"
                    :class="data.users.length > 1 ? 'mb-3' : ''"
                  >
                    <router-link :to="`/account/users/${user.id}`">
                      {{ `${user.family_name} ${user.given_name}` }}
                    </router-link>
                  </div>
                </div>
                <div v-else class="mx-3">なし</div>
              </td>
            </tr>
            <tr v-if="selectAreas.length">
              <td width="30%">エリア</td>
              <td>
                <div
                  v-if="(data.areas && data.areas.length == 1) || data.area_id"
                  class="mx-3 my-2"
                >
                  <router-link
                    :to="`/account/areas/${data.area_id || data.areas[0].id}`"
                  >
                    {{ areaName(data.area_id || data.areas[0].id) }}
                  </router-link>
                </div>
                <div v-else class="mx-3">なし</div>
                <!-- Userで使用。 -->
                <div
                  v-if="data.areas && data.areas.length >= 2"
                  class="mx-3 my-2"
                >
                  <div
                    v-for="(area, i) in data.areas"
                    :key="i"
                    class="mb-3"
                    :class="data.areas.length > 1 ? 'mb-3' : ''"
                  >
                    <router-link :to="`/account/areas/${area.id}`">
                      {{ area.v2_name.ja }}
                    </router-link>
                  </div>
                </div>
                <!-- <div
                  v-else-if="(data.areas && data.areas.length < 0) || (data.area_id && data.area_id.length < 0 )"
                  class="mx-3"
                >
                  なし
                </div> -->
              </td>
            </tr>
            <tr v-if="selectHotels.length">
              <td width="30%">施設</td>
              <td>
                <div v-if="data.hotels && data.hotels.length" class="mx-3 my-2">
                  <div
                    v-for="(hotel, i) in data.hotels"
                    :key="i"
                    class="mb-3"
                    :class="data.hotels.length > 1 ? 'mb-3' : ''"
                  >
                    <router-link :to="`/account/hotels/${hotel.id}`">
                      {{ hotel.name.ja }}
                    </router-link>
                  </div>
                </div>
                <div v-else class="mx-3">なし</div>
              </td>
            </tr>
            <tr v-if="selectShops.length">
              <td width="30%">店舗</td>
              <td>
                <div v-if="data.shops && data.shops.length" class="mx-3 my-2">
                  <div
                    v-for="(shop, i) in data.shops"
                    :key="i"
                    class="mb-3"
                    :class="data.shops.length > 1 ? 'mb-3' : ''"
                  >
                    <router-link :to="`/account/shops/${shop.id}`">
                      {{ shop.name.ja }}
                    </router-link>
                  </div>
                </div>
                <div v-else class="mx-3">なし</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //
      }
    },
    props: {
      hotel: Boolean,
      user: Boolean,
      shop: Boolean,
      value: { type: Object },
      users: { type: Array },
      areas: { type: Array },
      hotels: { type: Array },
      shops: { type: Array },
      selectUsers: { type: Array, default: () => [] },
      selectAreas: { type: Array, default: () => [] },
      selectHotels: { type: Array, default: () => [] },
      selectShops: { type: Array, default: () => [] },
    },
    computed: {
      data: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
      usersSelected: {
        get() {
          if (!this.data.users || !this.data.users.length) return null
          let idArray = []
          this.data.users.map((d) => idArray.push(d.id))
          return idArray
        },
        set(val) {
          this.data.user_id = val
          if (this.$route.params.id !== 'new') {
            this.data.users = val.map((v) => {
              return this.users.find((user) => user.id == v)
            })
          }
        },
      },
      // Shopで使用
      areaSelected: {
        get() {
          if (!this.data.areas || !this.data.areas.length) return null
          return this.data.area_id ? this.data.area_id : this.data.areas[0].id
        },
        set(val) {
          // this.data.area_id = val;
          this.$set(this.data, 'area_id', val)
        },
      },
      areasSelected: {
        get() {
          if (!this.data.areas || !this.data.areas.length) return null
          let idArray = []
          this.data.areas.map((d) => idArray.push(d.id))
          return idArray
        },
        set(val) {
          this.data.area_id = val
          if (this.data.id) {
            this.data.areas = val.map((v) => {
              return this.areas.find((area) => area.id == v)
            })
          }
        },
      },
      hotelsSelected: {
        get() {
          if (!this.data.hotels || !this.data.hotels.length) return null
          let idArray = []
          this.data.hotels.map((d) => idArray.push(d.id))
          return idArray
        },
        set(val) {
          this.data.hotel_id = val
          if (this.data.id) {
            this.data.hotels = val.map((v) => {
              return this.hotels.find((hotel) => hotel.id == v)
            })
          }
        },
      },
      shopsSelected: {
        get() {
          if (!this.data.shops || !this.data.shops.length) return null
          let idArray = []
          this.data.shops.map((d) => idArray.push(d.id))
          return idArray
        },
        set(val) {
          this.data.shop_id = val
          if (this.data.id) {
            this.data.shops = val.map((v) => {
              return this.shops.find((shop) => shop.id == v)
            })
          }
        },
      },
    },
    methods: {
      userName(id) {
        if (!id) return
        const user = this.selectUsers.find((d) => d.value == id)
        return user ? user.name : ''
      },
      areaName(id) {
        if (!id) return
        const area = this.selectAreas.find((d) => d.value == id)
        return area ? area.name : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .border {
    border-bottom: 1px solid #002c81;
  }
</style>
