import Vue from 'vue'

import TextFieldMain from '@/components/parts/TextFieldMain'
import TextAreaMain from '@/components/parts/TextAreaMain'
import SelectorMain from '@/components/parts/SelectorMain'
import TableLoading from '@/components/parts/TableLoading'
import DatePickerDialog from '@/components/parts/DatePickerDialog'

Vue.component('TextFieldMain', TextFieldMain)
Vue.component('TextAreaMain', TextAreaMain)
Vue.component('SelectorMain', SelectorMain)
Vue.component('TableLoading', TableLoading)
Vue.component('DatePickerDialog', DatePickerDialog)
