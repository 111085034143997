<template>
  <div class="pl-8">TOP PAGE</div>
</template>

<script>
// import * as SDK from '@am-area/sdk';
// import NavPanel from "@/components/NavPanel";
// import Routes from "@/datas/routes";
// import FrameModal from "@/components/FrameModal.vue";

export default {
  mixins: [],
  components: {
    //
  },
  data: () => ({}),
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
