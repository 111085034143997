<template>
  <frame-detail
    :loading="form.loading"
    @submit="update()"
    @delete="deleteItem()"
  >
    <template v-slot:title>
      <v-card-title v-if="$route.params.id === 'new'"
        >新規店舗作成</v-card-title
      >
      <v-card-title v-else>店舗詳細</v-card-title>
    </template>
    <template v-slot:main>
      <v-form
        ref="form"
        v-model="form.valid"
        @submit="update()"
        enctype="multipart/form-data"
      >
        <template v-if="data.name">
          <v-simple-table class="my-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <td width="50%">
                    <p class="font-weight-bold" style="color: #002c81">
                      店舗情報
                    </p>
                  </td>
                  <td width="50%" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>店舗名</td>
                  <td>
                    <TextFieldMain
                      v-model="data.name.ja"
                      label="日本語"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <TextFieldMain
                      v-for="(la, i) in Object.keys(data.name).filter(
                        (d) => d != 'ja'
                      )"
                      :key="i"
                      v-model="data.name[la]"
                      :label="la | lang"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>紹介文</td>
                  <td>
                    <TextAreaMain
                      v-model="data.desc.ja"
                      label="日本語"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextAreaMain>
                    <TextAreaMain
                      v-for="(la, i) in Object.keys(data.name).filter(
                        (d) => d != 'ja'
                      )"
                      :key="i"
                      v-model="data.desc[la]"
                      :label="la | lang"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextAreaMain>
                  </td>
                </tr>
                <tr>
                  <td>住所</td>
                  <td>
                    <TextFieldMain
                      v-model="data.postal_code"
                      label="郵便番号"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <v-select
                      v-model="data.address_level1"
                      :items="prefectures"
                      label="都道府県"
                      hide-details="auto"
                      outlined
                      dense
                      color="blue"
                      item-color="blue"
                      background-color="blue lighten-5"
                      class="my-2"
                      :rules="[formRules.required]"
                    ></v-select>
                    <TextFieldMain
                      v-model="data.address_level2"
                      label="市区町村"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <TextFieldMain
                      v-model="data.address_level3"
                      label="番地"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <TextFieldMain
                      v-model="data.address_level4"
                      label="マンション・アパート名"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>緯度</td>
                  <td>
                    <TextFieldMain
                      v-model="data.latitude"
                      label="緯度"
                      type="number"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>経度</td>
                  <td>
                    <TextFieldMain
                      v-model="data.longitude"
                      label="経度"
                      type="number"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>GoogleマップURL</td>
                  <td>
                    <TextFieldMain
                      v-model="data.map_url"
                      label="GoogleマップURL"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>公式サイト</td>
                  <td>
                    <TextFieldMain
                      v-model="data.url"
                      label="公式サイト"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>電話番号</td>
                  <td>
                    <TextFieldMain
                      v-model="data.tel"
                      label="電話番号"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>画像（メイン）</td>
                  <td>
                    <S3Image
                      v-if="data.image_main && !previewUrl"
                      :path="data.image_main"
                      :aspect-ratio="4 / 3"
                      class="my-2"
                      width="150"
                    />
                    <v-img
                      v-if="previewUrl"
                      :src="previewUrl"
                      :aspect-ratio="4 / 3"
                      class="my-2"
                      width="150"
                    >
                    </v-img>
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      v-model="file"
                      class="pb-1"
                      　hide-details="auto"
                      @change="
                        getFileContent($event).then((v) => (previewUrl = v))
                      "
                    >
                    </v-file-input>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <div class="border"></div>

        <!-- 営業情報 -->
        <v-simple-table class="my-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td width="50%">
                  <p class="font-weight-bold" style="color: #002c81">
                    営業情報
                  </p>
                </td>
                <td width="50%" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>手動休業フラグ</td>
                <td>
                  <v-switch
                    v-model="data.is_open"
                    dense
                    class="mt-0 pa-2"
                    hide-details="auto"
                    inset
                    :label="data.is_open ? '営業' : '休業'"
                  ></v-switch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="border"></div>

        <!-- 利用期間 -->
        <v-simple-table class="my-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td width="50%">
                  <p class="font-weight-bold" style="color: #002c81">
                    利用期間
                  </p>
                </td>
                <td width="50%" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>利用開始日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.started_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td>利用終了日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.ended_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <LinkItemList
          v-model="data"
          :users="users"
          :shop="shop"
          :areas="areas"
          :selectUsers="selectUsers"
          :selectAreas="selectAreas"
        />
      </v-form>
    </template>
  </frame-detail>
</template>

<script>
import { Storage } from "aws-amplify";
import S3Image from "@/components/S3Image.vue";
import { models } from "@am-area/sdk";
const { Shop, Auth } = models;

import FrameDetail from "@/components/FrameDetail.vue";
import TimePicker from "@/components/parts/TimePicker.vue";
import LinkItemList from "@/components/LinkItemList.vue";
import FormMixin from "@/mixins/Form.js";
import Prefectures from "@/datas/prefectures.json";

export default {
  mixins: [FormMixin],
  components: {
    Prefectures,
    S3Image,
    FrameDetail,
    TimePicker,
    LinkItemList,
  },

  data() {
    return {
      data: {},
      shop: true,
      previewUrl: null,
      file: {},
    };
  },
  props: {
    users: { type: Array, required: true },
    areas: { type: Array, required: true },
    selectUsers: { type: Array, required: true },
    selectAreas: { type: Array, required: true },
    getShops: { type: Function, required: true },
  },
  computed: {
    prefectures() {
      return Prefectures.map((o) => o.ja);
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.viewLoading = true;
      await Promise.all([this.getShop()]);
      this.viewLoading = false;
    },
    async getShop() {
      if (this.$route.params.id === "new") {
        this.data = await new Shop();
      } else {
        this.data = await Shop.get(this.$route.params.id, {
          with: "users,areas",
        });
      }

      // ブランク画像
      if (!this.data.image_main) {
        this.data.image_main = 'noimage_rectangle.jpg'
      }
    },
    async getFileContent($event) {
      return await this.readFileAsync($event).catch((error) =>
        console.log(error)
      );
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (f) => {
          resolve(f.target.result);
        };
        if (!file) resolve(null);
        reader.readAsDataURL(file);
      });
    },
    async update() {
      if (!this.$refs.form.validate()) {
        this.$root.isNotValidated_ = true;
        return;
      }

      this.viewLoading = true;
      await this.putFile();
      try {
        if (this.$route.params.id === "new") {
          const createUserId = await Auth.id;
          const updateUserId = await Auth.id;
          this.data.created_user_id = createUserId;
          this.data.updated_user_id = updateUserId;
          this.data = await Shop.create(this.data);
          await this.$router.replace({ params: { id: this.data.id } });
        } else {
          const updateUserId = await Auth.id;
          this.data.updated_user_id = updateUserId;
          this.data = await Shop.update(this.$route.params.id, this.data);
        }
        await this.getData();
        await this.getShops();
        await this.showSavedNotification();
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
    async deleteItem() {
      this.viewLoading = true;
      try {
        if (!confirm("削除しますか？")) return;
        this.data = await Shop.delete(this.$route.params.id);
        this.$router.replace("/account/shops");
        this.$root.isDeleted_ = true;
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
    async putFile() {
      const shop = this.data;
      const file = this.file;
      if (!file.name) return;

      // 元々設定されていた画像をStorageから削除（ブランク画像じゃない場合のみ）
      if (this.data.image_main != 'noimage_rectangle.jpg') {
        await Storage.remove(this.data.image_main)
      }

      const extension = file.name.split(".").reverse()[0];
      const result = await Storage.put(
        `shops/${shop.id}/main.${extension}`,
        file
      );
      this.data.image_main = result.key;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
}
.message {
  display: none;
}
.validated.null {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 16px;
  color: red;
}
.border {
  border-bottom: 1px solid #002c81;
}
</style>
