<template>
  <v-card :width="width" class="py-5 px-9" :class="{ shake: ui.shake }">
    <v-form @submit.prevent="signIn">
      <v-card-title class="d-flex justify-center">
        <v-img
          contain
          alt="AiFace Marketing Logo"
          src="@/assets/logo.png"
          max-width="100"
        />
        <div
          class="ml-9 font-weight-bold grey--text text--darken-3"
          style="font-size: 23px"
        >
          AiFace Marketing 管理サイト
        </div>
      </v-card-title>
      <v-divider class="my-4"></v-divider>
      <v-card-text>
        <span class="text-subtitle-1 font-weight-bold grey--text text--darken-2"
          >Staff User Id（Email）</span
        >
        <v-text-field
          v-model="req.email"
          type="email"
          name="email"
          label="Email"
          class="mt-1 mb-3"
          outlined
          dense
          single-line
          hide-details="auto"
        ></v-text-field>
        <span class="text-subtitle-1 font-weight-bold grey--text text--darken-2"
          >Password</span
        >
        <v-text-field
          v-model="req.password"
          @click:append="ui.showPassword = !ui.showPassword"
          :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="ui.showPassword ? 'text' : 'password'"
          name="password"
          label="Password"
          class="mt-1"
          outlined
          dense
          single-line
          hide-details="auto"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pa-4 d-flex justify-center">
        <v-btn
          color="primary"
          class="text-subtitle-1 font-weight-bold py-5"
          width="200"
          type="submit"
          :loading="loading"
          >ログイン</v-btn
        >
      </v-card-actions>
    </v-form>
    <div class="text-center mt-2 mb-4">
      <a
        class="grey--text text--darken-3"
        style="text-decoration: underline"
        @click="$emit('forgot-password')"
      >
        パスワードを忘れた場合
      </a>
    </div>
  </v-card>
</template>

<script>
  import { Hub } from 'aws-amplify'
  import { models } from '@am-area/sdk'
  const { Auth } = models
  export default {
    props: {
      width: String,
    },
    data: () => ({
      ui: {
        showPassword: false,
        shake: false,
      },
      req: {
        email: null, //'admin@psinc.jp',
        password: null, //'psincpsinc',
      },
      loading: false,
      error: null,
    }),
    methods: {
      async signIn() {
        this.ui.shake = false
        this.error = null
        this.loading = true
        try {
          const cognitoUser = await Auth.signIn(
            this.req.email,
            this.req.password
          )
          Hub.dispatch('Authenticator', {
            event: cognitoUser.challengeName || 'POST_SIGN_IN',
            message: '',
            data: {
              cognitoUser: cognitoUser,
            },
          })
        } catch (e) {
          switch (e.message) {
            case 'User does not exist.':
              this.error = 'メールアドレス、パスワードが一致しません。'
              break
            default:
              this.error = e.message
          }
          this.ui.shake = true
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
