<template>
  <v-dialog
    :width="width"
    :value="isOpen"
    @click:outside="
      modalClose();
      $emit('modal-close');
    "
  >
    <slot name="default"></slot>
  </v-dialog>
</template>
<script>
import * as SDK from '@am-area/sdk'
export default {
  props: {
    width: { type: Number, default: 600 },
    name: { type: String },
  },
  computed: {
    isOpen() {
      return this.modalCheck(this.name);
    },
  },
};
</script>