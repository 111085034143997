import { render, staticRenderFns } from "./Hotel.vue?vue&type=template&id=3ff24f66&scoped=true&"
import script from "./Hotel.vue?vue&type=script&lang=js&"
export * from "./Hotel.vue?vue&type=script&lang=js&"
import style0 from "./Hotel.vue?vue&type=style&index=0&id=3ff24f66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff24f66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCardTitle,VForm,VRadio,VRadioGroup,VSimpleTable})
