<template>
  <router-view
    :miniNav="miniNav"
    :users="users"
    :areas="areas"
    :hotels="hotels"
    :shops="shops"
    :selectUsers="selectUsers"
    :selectAreas="selectAreas"
    :selectHotels="selectHotels"
    :selectShops="selectShops"
    :getUsers="getUsers"
    :getAreas="getAreas"
    :getHotels="getHotels"
    :getShops="getShops"
  />
</template>

<script>
  import { models } from '@am-area/sdk'
  const { User } = models
  const { Area } = models
  const { Hotel } = models
  const { Shop } = models

  export default {
    name: 'Accounts',

    data: () => ({
      users: [],
      areas: [],
      hotels: [],
      shops: [],
      selectUsers: [],
      selectAreas: [],
      selectHotels: [],
      selectShops: [],
    }),
    props: {
      miniNav: { type: Boolean, default: false },
    },
    async created() {
      await Promise.all([
        this.getUsers(),
        this.getAreas(),
        this.getHotels(),
        this.getShops(),
      ])
    },
    methods: {
      async getUsers() {
        const users = await User.index()
        this.users = users
        this.selectUsers = users.map((d) => {
          return {
            text: `${d.family_name} ${d.given_name}`,
            value: d.id,
            name: `${d.family_name} ${d.given_name}`,
          }
        })
      },
      async getAreas() {
        const areas = await Area.index()
        this.areas = areas
        this.selectAreas = areas.map((d) => {
          return {
            text: `${d.v2_name.ja}`,
            value: d.id,
            name: d.v2_name.ja,
          }
        })
      },
      async getHotels() {
        const hotels = await Hotel.index()
        this.hotels = hotels
        this.selectHotels = hotels.map((d) => {
          return {
            text: `${d.name.ja}`,
            value: d.id,
            name: d.name.ja,
          }
        })
      },
      async getShops() {
        const shops = await Shop.index()
        this.shops = shops
        this.selectShops = shops.map((d) => {
          return {
            text: `${d.name.ja}`,
            value: d.id,
            name: d.name.ja,
          }
        })
      },
    },
  }
</script>
