<template>
  <v-textarea
    :value="value"
    outlined
    color="blue"
    :rules="rules"
    :counter="counter"
    :hint="hint"
    :label="label"
    :readonly="readonly"
    :placeholder="placeholder"
    :rows="rows"
    hide-details="auto"
    background-color="blue lighten-5"
    @input="onInput($event, value)"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: String,
      default: () => '',
    },
    hint: {
      type: String,
      default: () => '',
    },
    counter: {
      type: Number,
      default: () => null,
    },
    maxNum: {
      type: Number,
      default: () => 1000,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    rows: {
      type: Number,
      default: () => 5,
    },
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event)
    },
  },
}
</script>

<style lang="scss" scoped></style>
