<template>
  <div v-if="!hide" class="d-flex align-center justify-end">
    <v-btn v-if="cancellable" @click="$router.go(-1)">キャンセル</v-btn>
    <v-btn
      dark
      color="#002C81"
      class="mr-3 px-10"
      @click="$emit('submit')"
      >{{ $route.params.id === "new" ? "登録" : "更新" }}</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    cancellable: { type: Boolean, default: false },
  },
};
</script>