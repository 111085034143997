<template>
  <frame-detail
    :loading="form.loading"
    @submit="update()"
    @delete="deleteItem()"
  >
    <template v-slot:title>
      <v-card-title v-if="$route.params.id === 'new'"
        >新規施設作成</v-card-title
      >
      <v-card-title v-else>施設情報</v-card-title>
    </template>
    <template v-slot:main>
      <v-form ref="form" v-model="form.valid" @submit="update()">
        <template v-if="data.name">
          <v-simple-table class="my-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <td width="50%">
                    <p class="font-weight-bold" style="color: #002c81">
                      施設情報
                    </p>
                  </td>
                  <td width="50%" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>施設名</td>
                  <td>
                    <TextFieldMain
                      v-model="data.name.ja"
                      label="日本語"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <TextFieldMain
                      v-for="(la, i) in Object.keys(data.name).filter(
                        (d) => d != 'ja'
                      )"
                      :key="i"
                      v-model="data.name[la]"
                      :label="la | lang"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>施設情報</td>
                  <td>
                    <div class="mt-2">
                      ご利用中のPMS
                      <v-radio-group v-model="pmsSelected" row class="ml-2">
                        <v-radio label="AiFace" value="AiFace"></v-radio>
                        <v-radio label="その他" value="Others"></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="d-flex align-center">
                      <HotelMasterDialog
                        :hotel-name="data.name.ja"
                        class="mr-2"
                        @selectHotel="data.hotel_master_id = $event"
                        >ホテルマスタID検索</HotelMasterDialog
                      >
                      <TextFieldMain
                        v-model="data.hotel_master_id"
                        label="ホテルマスタID"
                        hide-details="auto"
                        outlined
                        dense
                        class="my-2"
                        :rules="[formRules.required]"
                      ></TextFieldMain>
                    </div>
                    <TextFieldMain
                      v-model="data.rc_master_id"
                      label="RCお客様番号"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <template v-if="pmsSelected == 'AiFace'">
                      <TextFieldMain
                        v-model="data.group_code"
                        label="グループコード"
                        hide-details="auto"
                        outlined
                        dense
                        class="my-2"
                        :rules="[formRules.required]"
                      ></TextFieldMain>
                      <TextFieldMain
                        v-model="data.hotel_code"
                        label="ホテルコード"
                        hide-details="auto"
                        outlined
                        dense
                        class="my-2"
                        :rules="[formRules.required]"
                      ></TextFieldMain>
                    </template>
                  </td>
                </tr>
                <tr class="mt-2">
                  <td>ゲスト属性登録ページ</td>
                  <td>
                    <div>
                      <v-radio-group
                        v-model="data.show_guest_attributes_register_page"
                        row
                        class="ml-2"
                      >
                        <v-radio label="表示" :value="true"></v-radio>
                        <v-radio label="非表示" :value="false"></v-radio>
                      </v-radio-group>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <div class="border"></div>

        <!-- 利用期間 -->
        <v-simple-table class="my-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td width="50%">
                  <p class="font-weight-bold" style="color: #002c81">
                    利用期間
                  </p>
                </td>
                <td width="50%" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>利用開始日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.started_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td>利用終了日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.ended_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <LinkItemList
          v-model="data"
          :users="users"
          :areas="areas"
          :hotel="hotel"
          :selectUsers="selectUsers"
          :selectAreas="selectAreas"
        />
      </v-form>
    </template>
  </frame-detail>
</template>

<script>
import { models } from "@am-area/sdk";
const { Hotel, Auth } = models;

import FrameDetail from "@/components/FrameDetail.vue";
import LinkItemList from "@/components/LinkItemList.vue";
import FormMixin from "@/mixins/Form.js";
import HotelMasterDialog from "@/components/HotelMasterDialog.vue";

export default {
  mixins: [FormMixin],
  components: {
    HotelMasterDialog,
    FrameDetail,
    LinkItemList,
  },

  data() {
    return {
      data: {},
      hotel: true,
      // selectAreas:[],
      pmsSelected: "Others",
    };
  },
  props: {
    users: { type: Array, required: true },
    areas: { type: Array, required: true },
    selectUsers: { type: Array, required: true },
    selectAreas: { type: Array, required: true },
    getHotels: { type: Function, required: true },
  },
  async created() {
    await this.getData();
    // await this.getAreas();
  },
  methods: {
    async getData() {
      this.viewLoading = true;
      await Promise.all([this.getHotel()]);
      this.viewLoading = false;
    },
    async getHotel() {
      if (this.$route.params.id === "new") {
        this.data = await new Hotel();
        // this.data.name = { en: "", ja: "", ko: "", zh_CN: "", zh_TW: "" };
      } else
        this.data = await Hotel.get(this.$route.params.id, {
          with: "users,area",
        });
    },
    //   async getAreas() {
    //   this.selectAreas = this.data.area.map((d) => {
    //     return {
    //       text: `${d.id} (${d.name})`,
    //       value: d.id,
    //       name: d.name,
    //     };
    //   });
    // },
    async update() {
      if (!this.$refs.form.validate()) {
        this.$root.isNotValidated_ = true;
        return;
      }

      this.viewLoading = true;
      try {
        if (this.$route.params.id === "new") {
          const createUserId = await Auth.id;
          const updateUserId = await Auth.id;
          this.data.created_user_id = createUserId;
          this.data.updated_user_id = updateUserId;
          this.data = await Hotel.create(this.data);
          await this.$router.replace({ params: { id: this.data.id } });
        } else {
          const updateUserId = await Auth.id;
          this.data.updated_user_id = updateUserId;
          this.data = await Hotel.update(this.$route.params.id, this.data);
        }
        await this.getData();
        await this.getHotels();
        await this.showSavedNotification();
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
    async deleteItem() {
      this.viewLoading = true;
      try {
        if (!confirm("削除しますか？")) return;
        this.data = await Hotel.delete(this.$route.params.id);
        this.$router.replace("/account/hotels");
        this.$root.isDeleted_ = true;
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
}
.message {
  display: none;
}
.validated.null {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 16px;
  color: red;
}
.border {
  border-bottom: 1px solid #002c81;
}
</style>
