<template>
  <v-navigation-drawer
    app
    permanent
    :mini-variant="miniNav"
    width="270"
    clipped
    color="grey lighten-4"
    class="font-weight-bold"
  >
    <nav>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-on:click="miniNav = !miniNav">mdi-menu</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>
        <template v-for="(route, index) in routes">
          <!-- 多階層 -->
          <template
            v-if="
              route.path == '/account' &&
              route.children &&
              route.children.length
            "
          >
            <v-list-group no-action :key="index" :prepend-icon="route.icon">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold" v-text="route.name" />
                </v-list-item-content>
              </template>
              <template v-for="child in route.children">
                <template>
                  <v-list-item
                    :key="child.label"
                    :to="child.path"
                    style="font-size: 0.9rem"
                    class="font-weight-bold"
                  >
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 0.9rem" v-text="child.name" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>
          <!-- １階層 -->
          <template v-else>
            <v-list-item :key="route.name" :to="route.path">
              <v-list-item-icon>
                <v-icon v-text="route.icon" />
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold" v-text="route.name" />
            </v-list-item>
          </template>
        </template>
      </v-list>
    </nav>
    <template v-slot:append>
      <v-list dense nav>
        <v-list-item link @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-login</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">ログアウト</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="pb-6"></div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Routes from "@/datas/routes";
import _ from "lodash";
import { models } from "@am-area/sdk";
const { Auth } = models;

export default {
  components: {
    Routes,
  },
  data() {
    return {
      //
    };
  },
  props: {
    mini: { type: Boolean, default: false },
  },
  computed: {
    miniNav: {
      get() {
        return this.mini
      },
      set(val) {
        this.$emit('minimize', val)
      },
    },
    routes() {
      return Routes.filter((route) => route.type == "main");
    },
  },
  methods: {
    async signOut() {
      await Auth.signOut();
    },
  },
};
</script>