<template>
  <v-card :width="width" class="mx-auto" :class="{ shake: ui.shake }">
    <v-form @submit.prevent="submit">
      <v-card-title>パスワードを設定して下さい</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="req.newPassword"
          @click:append="ui.showPassword = !ui.showPassword"
          :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="ui.showPassword ? 'text' : 'password'"
          name="new-password"
          label="パスワード"
          hint="半角英数記号8文字以上"
        ></v-text-field>
        <v-text-field
          v-model="req.newPasswordConfirm"
          @click:append="ui.showPassword = !ui.showPassword"
          :append-icon="ui.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="ui.showPassword ? 'text' : 'password'"
          name="new-password"
          label="パスワード（確認用）"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit">設定する</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { models } from '@am-area/sdk'
  // const { Auth } = models
  import { Auth, Hub } from 'aws-amplify'
  export default {
    created() {
      // Hub.listen("auth", async (data) => {
      //   switch (data.payload.event) {
      //     // 認証失敗
      //     case "signIn_failure":
      //       this.ui.shake = true;
      //       break;
      //   }
      // });
    },
    props: {
      width: String,
      cognitoUser: Object,
    },
    data: () => ({
      ui: {
        showPassword: false,
        shake: false,
      },
      req: {
        newPassword: null,
        newPasswordConfirm: null,
      },
    }),
    methods: {
      async submit() {
        this.ui.shake = false
        try {
          const cognitoUser = this.cognitoUser
          const newPassword = this.req.newPassword
          const newPasswordConfirm = this.req.newPasswordConfirm
          if (newPassword != newPasswordConfirm) {
            throw new Error('確認用パスワードが一致しません。')
          }
          await Auth.completeNewPassword(cognitoUser, newPassword)
          Hub.dispatch('Authenticator', {
            event: cognitoUser.challengeName || 'POST_SIGN_IN',
            message: '',
            data: {
              cognitoUser: cognitoUser,
            },
          })
        } catch (e) {
          console.error(e.message)
          this.ui.shake = true
          await new Promise((r) => setTimeout(r, 500))
          this.ui.shake = false
        }
      },
    },
  }
</script>
