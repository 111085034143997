export default {
  async created () {
    //
  },
  data () {
    return {
      user: {},
      viewLoading_: false, // 各ビューでのデータロード中を表すステータス
      tableLoading_: false, // 各テーブルでのデータロード中を表すステータス
      modal: null, // 表示中のモーダル名
      isNotCreateUcPassCode_: false,
      axiosError_: null, // error
      isSaved_: false,// "保存しました"を右上に表示
      isDeleted_: false, //　"削除しました"
      isNotValidated_: false,// "フォームバリデーションエラー"を右上に表示
      isError_: false,// "フォームバリデーションエラー"を右上に表示
      guestPageLangs_: [], // ゲストページ使用言語
    }
  },
  methods: {
    //
  },
}