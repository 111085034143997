<template>
  <frame-detail
    :loading="form.loading"
    @submit="update()"
    @delete="deleteItem()"
  >
    <template v-slot:title>
      <v-card-title v-if="$route.params.id === 'new'"
        >新規エリア作成</v-card-title
      >
      <v-card-title v-else>エリア情報</v-card-title>
    </template>
    <template v-slot:main>
      <v-form ref="form" v-model="form.valid" @submit="update()">
        <template v-if="data.v2_name">
          <v-simple-table class="my-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <td width="50%">
                    <p class="font-weight-bold" style="color: #002c81">
                      エリア情報
                    </p>
                  </td>
                  <td width="50%" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>エリア名</td>
                  <td>
                    <TextFieldMain
                      v-model="data.v2_name.ja"
                      label="日本語"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                    <TextFieldMain
                      v-for="(la, i) in Object.keys(data.v2_name).filter(
                        (d) => d != 'ja'
                      )"
                      :key="i"
                      v-model="data.v2_name[la]"
                      :label="la | lang"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required]"
                    ></TextFieldMain>
                  </td>
                </tr>
                <tr>
                  <td>紹介文</td>
                  <td>
                    <TextAreaMain
                      v-model="data.v2_desc.ja"
                      label="日本語"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                    ></TextAreaMain>
                    <TextAreaMain
                      v-for="(la, i) in Object.keys(data.v2_name).filter(
                        (d) => d != 'ja'
                      )"
                      :key="i"
                      v-model="data.v2_desc[la]"
                      :label="la | lang"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                    ></TextAreaMain>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <div class="border"></div>

        <!-- 利用期間 -->
        <v-simple-table class="my-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td width="50%">
                  <p class="font-weight-bold" style="color: #002c81">
                    利用期間
                  </p>
                </td>
                <td width="50%" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>利用開始日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.started_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td>利用終了日</td>
                <td class="py-3">
                  <DatePickerDialog
                    v-model="data.ended_on"
                    :rules="[formRules.required]"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <LinkItemList
          v-model="data"
          :users="users"
          :hotels="hotels"
          :shops="shops"
          :selectUsers="selectUsers"
          :selectHotels="selectHotels"
          :selectShops="selectShops"
        />
      </v-form>
    </template>
  </frame-detail>
</template>

<script>
import { models } from "@am-area/sdk";
const { Area, Auth, Hotel } = models;

import FrameDetail from "@/components/FrameDetail.vue";
import LinkItemList from "@/components/LinkItemList.vue";
import FormMixin from "@/mixins/Form.js";

export default {
  mixins: [FormMixin],
  components: { FrameDetail, LinkItemList },
  data() {
    return {
      data: {},
      area_id: "",

      // 修正前の紐付け施設を保持
      hotelsBeforeFix: [],
    };
  },
  props: {
    users: { type: Array, required: true },
    hotels: { type: Array, required: true },
    shops: { type: Array, required: true },
    selectUsers: { type: Array, required: true },
    selectHotels: { type: Array, required: true },
    selectShops: { type: Array, required: true },
    getAreas: { type: Function, required: true },
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.viewLoading = true;
      await this.getArea();
      this.viewLoading = false;
      this.area_id = this.$route.params.id;
    },
    async getArea() {
      if (this.$route.params.id === "new") {
        this.data = await new Area();
      } else {
        this.data = await Area.get(this.$route.params.id, {
          with: "hotels,shops,users",
        });
        this.hotelsBeforeFix = this.data.hotels;
      }
    },
    async update() {
      if (!this.$refs.form.validate()) {
        this.$root.isNotValidated_ = true;
        return;
      }

      this.viewLoading = true;
      try {
        if (this.$route.params.id === "new") {
          const createUserId = await Auth.id;
          const updateUserId = await Auth.id;
          this.data.created_user_id = createUserId;
          this.data.updated_user_id = updateUserId;
          this.data = await Area.create(this.data);
          await this.$router.replace({ params: { id: this.data.id } });
        } else {
          const updateUserId = await Auth.id;
          this.data.updated_user_id = updateUserId;

          await Promise.all([
            // 施設紐付け
            await this.data.hotels.map(async (hotel, i) => {
              const data = await Hotel.get(hotel.id);
              data.area_id = this.area_id;
              await Hotel.update(hotel.id, data);
            }),
            // 施設紐付け解除
            this.hotelsBeforeFix.map(async (hotel, i) => {
              const isHotelSelected = this.data.hotel_id.includes(hotel.id);
              if(!isHotelSelected) {
                const data = await Hotel.get(hotel.id);
                data.area_id = "";
                await Hotel.update(hotel.id, data);
              }
            }),
          ]);

          this.data = await Area.update(this.$route.params.id, this.data);
        }
        await this.getData();
        await this.getAreas();
        await this.showSavedNotification();
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
    async deleteItem() {
      this.viewLoading = true;
      try {
        if (!confirm("削除しますか？")) return;
        this.data = await Area.delete(this.$route.params.id);
        this.$router.replace("/account/areas");
        this.$root.isDeleted_ = true;
      } catch (err) {
        throw err;
      } finally {
        this.viewLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
}

.message {
  display: none;
}

.validated.null {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 16px;
  color: red;
}

.border {
  border-bottom: 1px solid #002c81;
}
</style>
