import { render, staticRenderFns } from "./Area.vue?vue&type=template&id=60a8c3ac&scoped=true&"
import script from "./Area.vue?vue&type=script&lang=js&"
export * from "./Area.vue?vue&type=script&lang=js&"
import style0 from "./Area.vue?vue&type=style&index=0&id=60a8c3ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a8c3ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCardTitle,VForm,VSimpleTable})
