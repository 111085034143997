<template>
  <header
    class="pb-4"
    style="
      position: fixed;
      z-index: 1;
      height: 50px;
      background: #f7f9f8;
      border-bottom: 1px solid #e0e0e0;
    "
    :style="{'width': miniNav ? 'calc(100% - 56px)' : 'calc(100% - 280px)'}"
  >
    <div
      class="d-flex justify-space-between align-center px-4"
      style="width: 100%"
    >
      <div>
        <v-btn
          dark
          color="green darken-1"
          class="font-weight-bold"
          link
          :to="`${$route.path}/new`"
          >{{ buttonText }}</v-btn
        >
      </div>

      <div class="d-flex align-center justify-space-between">
        <v-text-field
          v-model="searchParams.name"
          :label="nameLabel"
          dense
          style="width: 270px"
          hide-details="auto"
          @keydown.enter.prevent="search"
        ></v-text-field>

        <v-text-field
          v-if="$route.name === 'Users'"
          v-model="searchParams.email"
          label="メールアドレス検索"
          dense
          class="ml-4"
          style="width: 250px"
          hide-details="auto"
          @change="search"
        ></v-text-field>

        <v-autocomplete
          v-if="
            $route.name === 'Areas' ||
            $route.name === 'Hotels' ||
            $route.name === 'Shops'
          "
          v-model="searchParams.ownerId"
          :items="selectUsers"
          label="主アカウントID検索"
          dense
          class="ml-4 text-caption"
          style="width: 320px"
          hide-details="auto"
          @change="search"
        ></v-autocomplete>

        <v-btn
          class="ml-4"
          color="primary"
          data-test="search-btn"
          @click="search"
        >
          検索
        </v-btn>
        <v-btn color="secondary" class="ml-2 text-caption" @click="resetParams()">
          条件クリア
        </v-btn>
        <v-btn class="ml-4" rounded dark color="orange" @click="allShow">
          全件表示
        </v-btn>
      </div>
    </div>
  </header>
</template>

<script>
import { models } from "@am-area/sdk";
const { User } = models;

export default {
  components: {
    //
  },
  props: {
    miniNav: { type: Boolean, default: false },
  },
  data() {
    return {
      searchParams: {
        name: "",
        email: "",
        ownerId: "",
      },
      selectUsers: [],
    };
  },
  async created() {
    await this.syncQuery();
    await this.getUsers();
  },
  computed: {
    nameLabel() {
      let text;
      switch (this.$route.name) {
        case "Users":
          text = "名前検索";
          break;
        case "Areas":
          text = "エリア名検索";
          break;
        case "Hotels":
          text = "施設名検索";
          break;
        case "Shops":
          text = "店舗名検索";
          break;
        default:
          text = "名前検索";
      }
      return text;
    },
    buttonText() {
      let text;
      switch (this.$route.name) {
        case "Users":
          text = "新規アカウント作成";
          break;
        case "Areas":
          text = "新規エリア作成";
          break;
        case "Hotels":
          text = "新規施設作成";
          break;
        case "Shops":
          text = "新規店舗作成";
          break;
        default:
          text = "新規アカウント作成";
      }
      return text;
    },
  },
  watch: {
    //
  },
  methods: {
    async getUsers() {
      const users = await User.index();
      this.selectUsers = users.map((d) => {
        return {
          text: `${d.id} (${d.family_name} ${d.given_name})`,
          value: d.id,
        };
      });
    },
    syncQuery() {
      this.searchParams = _.cloneDeep(this.$route.query);
    },
    async search() {
      const query = this.trimParams({ ...this.searchParams })
      await this.$router.replace({ query: query });
      this.$emit("search");
    },
    allShow() {
      this.searchParams = {};
      this.search();
    },
    resetParams() {
      Object.keys(this.searchParams).map((k) => {
        this.searchParams[k] = ''
      })
    },
  },
};
</script>
