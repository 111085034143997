import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MainMixin from '@/mixins/Main.js'

import MainPlugin from '@/plugins/Main.js'
import ValidationPlugin from '@/plugins/Validations.js'
import FilterPlugin from '@/plugins/Filters.js'
import FramePlugin from '@/plugins/Frame.js'
import ComponentsPlugin from '@/plugins/Components.js'
// import ConstantsPlugin from '@/plugins/Constants.js'
// import AuthPlugin from '@/plugins/Auth.js'
import Required from '@/components/Required'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

// 開発用デバッグ情報 - リリース時コメントアウト！
Vue.config.productionTip = true
Vue.config.devtools = true

Vue.use(MainPlugin)
Vue.use(ValidationPlugin)
Vue.use(FilterPlugin)
Vue.use(FramePlugin)
// Vue.use(ComponentsPlugin)
// Vue.use(ConstantsPlugin)
// Vue.use(AuthPlugin)

Vue.component('Required', Required)

new Vue({
  router,
  mixins: [MainMixin],
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
