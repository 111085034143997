import { render, staticRenderFns } from "./Shop.vue?vue&type=template&id=3652d6ae&scoped=true&"
import script from "./Shop.vue?vue&type=script&lang=js&"
export * from "./Shop.vue?vue&type=script&lang=js&"
import style0 from "./Shop.vue?vue&type=style&index=0&id=3652d6ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3652d6ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCardTitle,VFileInput,VForm,VImg,VSelect,VSimpleTable,VSwitch})
