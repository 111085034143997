<template>
  <tbody v-show="loading">
    <tr>
      <td style="background: transparent">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
