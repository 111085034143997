<template>
  <frame-detail :loading="form.loading" @submit="update()">
    <template v-slot:title>
      <v-card-title v-if="$route.params.id === 'new'"
        >新規アカウント作成</v-card-title
      >
      <v-card-title v-else>アカウント詳細</v-card-title>
      <div v-if="$route.params.id !== 'new'">
        <v-btn
          small
          dark
          outlined
          class="font-weight-bold"
          color="error"
          @click="deleteItem()"
          ><v-icon>mdi-delete</v-icon>削除</v-btn
        >
        <!-- <v-btn
                  small
                  dark
                  class="font-weight-bold ml-6 mr-3"
                  color="error"
                  link
                  :disabled="!data.email"
                  :href="`${userAppBaseUrl}/emulation?user=${$route.params.id}&email=${data.email}`"
                  target="_blank"
                  >ログイン</v-btn
                > -->
      </div>
    </template>
    <template v-slot:main>
      <v-form ref="form" v-model="form.valid" @submit="update()">
        <v-simple-table class="my-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td width="50%">
                  <p class="font-weight-bold" style="color: #002c81">
                    アカウント情報
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>名前</td>
                <td>
                  <TextFieldMain
                    v-model="data.family_name"
                    label="姓"
                    hide-details="auto"
                    outlined
                    dense
                    class="my-2"
                    :rules="[formRules.required]"
                  ></TextFieldMain>
                  <TextFieldMain
                    v-model="data.given_name"
                    label="名"
                    hide-details="auto"
                    outlined
                    dense
                    class="my-2"
                    :rules="[formRules.required]"
                  ></TextFieldMain>
                  <TextFieldMain
                    v-model="data.family_name_kana"
                    label="姓（カナ）"
                    hide-details="auto"
                    outlined
                    dense
                    class="my-2"
                    :rules="[formRules.required]"
                  ></TextFieldMain>
                  <TextFieldMain
                    v-model="data.given_name_kana"
                    label="名（カナ）"
                    hide-details="auto"
                    outlined
                    dense
                    class="my-2"
                    :rules="[formRules.required]"
                  ></TextFieldMain>
                </td>
              </tr>
              <tr>
                <td>メールアドレス</td>
                <td>
                  <TextFieldMain
                    v-model="data.email"
                    label="メールアドレス"
                    hide-details="auto"
                    outlined
                    dense
                    class="my-2"
                    :rules="[formRules.required]"
                  ></TextFieldMain>
                </td>
              </tr>
              <tr v-if="data.temporary_password">
                <td>仮パスワード</td>
                <td>
                  <TextFieldMain
                    v-model="data.temporary_password"
                    label="仮パスワード"
                    hide-details="auto"
                    type="text"
                    outlined
                    dense
                    class="my-2"
                    readonly
                  ></TextFieldMain>
                </td>
              </tr>
              <tr v-if="$route.params.id === 'new'">
                <td class="pt-4">
                  仮ログインパスワード<br />
                  <span class="text-caption"
                    >半角アルファベット(大文字と小文字)、数字、記号を含め、8文字以上で設定してください。</span
                  ><br />
                  <span class="text-caption red--text font-weight-bold"
                    >※現状、登録後はパスワードの呼び出しができないのでメモに残してください。</span
                  >
                </td>
                <td>
                  <v-row class="mx-1" align="center">
                    <TextFieldMain
                      v-model="data.temporary_password"
                      label="仮ログインパスワード"
                      hide-details="auto"
                      outlined
                      dense
                      class="my-2"
                      :rules="[formRules.required, formRules.password]"
                    ></TextFieldMain>
                    <v-btn class="ml-3" @click="createPass">再生成</v-btn>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </template>
  </frame-detail>
</template>

<script>
  import FormMixin from '@/mixins/Form.js'
  import { models } from '@am-area/sdk'
  const { User } = models
  const { Admin } = models
  import FrameDetail from '@/components/FrameDetail.vue'

  export default {
    mixins: [FormMixin],
    components: {
      FrameDetail,
    },

    data() {
      return {
        data: {},
      }
    },

    computed: {
      //
    },

    async created() {
      await this.getUser()
    },

    methods: {
      async getUser() {
        if (this.$route.params.id === 'new') {
          this.data = await new Admin()
          await this.createPass()
        } else {
          this.data = await Admin.get(this.$route.params.id)
        }
        this.data.type = 'Admin'
      },
      async update() {
        if (!this.$refs.form.validate()) {
          this.$root.isNotValidated_ = true
          return
        }

        this.viewLoading = true
        try {
          if (this.$route.params.id === 'new') {
            this.data = await Admin.create(this.data)
            await this.$router.replace({ params: { id: this.data.id } })
          } else {
            this.data = await Admin.update(this.$route.params.id, this.data)
          }
          await this.getUser()
          await this.showSavedNotification()
        } catch (err) {
          throw err
        } finally {
          this.viewLoading = false
        }
      },
      createPass() {
        const lettersLower = 'ABCDEFGHIJKLNMOPQRSTUZWXY'
        const lettersUpper = 'abcdefghijklmnopqrstuvwxyz'
        const numbers = '0123456789'
        const symbols = '$@'
        let a = ''
        let b = ''
        let c = ''
        let d = ''
        const len = 3 // 文字数
        this.data.temporary_password = '' // 文字列が空っぽという定義をする
        for (let i = 0; i < len; i++) {
          a += lettersLower.charAt(
            Math.floor(Math.random() * lettersLower.length)
          )
        }
        for (let i = 0; i < len; i++) {
          b += lettersUpper.charAt(
            Math.floor(Math.random() * lettersUpper.length)
          )
        }
        for (let i = 0; i < len; i++) {
          c += numbers.charAt(Math.floor(Math.random() * numbers.length))
        }
        for (let i = 0; i < 1; i++) {
          d += symbols.charAt(Math.floor(Math.random() * symbols.length))
        }
        this.data.temporary_password = a + b + d + c
      },
      async deleteItem() {
        this.viewLoading = true
        try {
          if (!confirm('削除しますか？')) return
          this.data = await User.delete(this.$route.params.id)
          this.$router.replace('/admins')
          this.$root.isDeleted_ = true
        } catch (err) {
          throw err
        } finally {
          this.viewLoading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  input {
    border: 1px solid #cecece;
    border-radius: 5px;
    width: 100%;
    padding: 8px;
    margin: 4px 0;
  }
  .message {
    display: none;
  }
  .validated.null {
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 16px;
    color: red;
  }
  .border {
    border-bottom: 1px solid #002c81;
  }
</style>
