<template>
  <v-select
    :value="value"
    :items="items"
    :rules="rules"
    color="blue"
    dense
    item-color="blue"
    hide-details="auto"
    background-color="blue lighten-5"
    :placeholder="placeholder"
    :return-object="returnObject"
    :item-text="itemText"
    outlined
    class="mb-2"
    @change="($event) => $emit('change', $event)"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      validator: (v) => true,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => '選択してください',
    },
    itemText: {
      type: String,
      default: () => '',
    },
    returnObject: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
