<template>
  <div class="d-flex flex-column">
    <v-sheet rounded class="pa-2 d-flex align-center" color="blue lighten-5">
      <v-select
        v-model="hour"
        :items="addHours"
        dense
        hide-details="auto"
        style="max-width: 80px"
        :disabled="disabled"
        :rules="rules"
      >
      </v-select>
      <span class="px-1">:</span>
      <v-select
        v-model="time"
        :items="times"
        dense
        hide-details="auto"
        style="max-width: 80px"
        :disabled="disabled"
        :rules="rules"
      >
      </v-select>
    </v-sheet>
  </div>
</template>
<style scoped>
div.v-select__selections {
  flex-wrap: nowrap !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
</style>
<script>
export default {
  props: {
    value: { type: String },
    rules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    _() {
      return window._
    },
    hours() {
      return Array.from({ length: 30 }).map((_, i) => {
        return {
          value: i.toString(),
          text: i.toString(),
        }
      })
    },
    times() {
      return ['00', '30']
    },
    addHours() {
      return this.hours
    },
    hour: {
      get: function () {
        let temp = this.value ? Number(this.value.split(':')[0]) : null
        const hour = this.value ? String(temp) : null
        return hour
      },
      set: function (v) {
        if (this.time) {
          this.$emit('input', `${v}:${this.time}`)
        } else {
          this.$emit('input', `${v}:00`)
        }
      },
    },
    time: {
      get: function () {
        return this.value ? this.value.split(':')[1] : null
      },
      set: function (v) {
        if (this.hour) {
          this.$emit('input', `${this.hour}:${v}`)
        } else {
          this.$emit('input', `1:${v}`)
        }
      },
    },
  },
  methods: {},
}
</script>
