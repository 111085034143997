<template>
  <div>
    <HeaderOfList @search="getUsers()" :miniNav="miniNav" />
    <v-container class="" style="padding-top: 60px; max-width: 1200px">
      <div class="pl-2 pb-2">{{ data.length }}件</div>
      <div class="px-3 py-3" style="background-color: #fff">
        <v-simple-table style="width: 100%">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="200">名前</th>
                <th width="200">メールアドレス</th>
                <th>主エリア</th>
                <th>主施設</th>
                <th>主店舗</th>
                <th width="164">管理</th>
              </tr>
            </thead>
            <TableLoading v-if="tableLoading" :loading="tableLoading" />
            <tbody v-else>
              <tr v-for="(d, i) in data" :key="i">
                <td class="td">{{ `${d.family_name} ${d.given_name}` }}</td>
                <td class="td">{{ d.email }}</td>
                <td style="word-break: break-all">
                  <div
                    v-if="d.areas && d.areas.length"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="(area, i) in d.areas.filter(
                        (area) =>
                          area.owner_id == d.id || area.pivot.user_id == d.id
                      )"
                      :key="i"
                    >
                      <router-link
                        :to="`/account/areas/${area.id}`"
                        class="mr-3"
                      >
                        {{ area.v2_name.ja }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td style="word-break: break-all">
                  <div
                    v-if="d.hotels && d.hotels.length"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="(hotel, i) in d.hotels.filter(
                        (hotel) =>
                          hotel.owner_id == d.id || hotel.pivot.user_id == d.id
                      )"
                      :key="i"
                    >
                      <router-link
                        :to="`/account/hotels/${hotel.id}`"
                        class="mr-3"
                      >
                        {{ hotel.name.ja }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td style="word-break: break-all">
                  <div
                    v-if="d.shops && d.shops.length"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="(shop, i) in d.shops.filter(
                        (shop) =>
                          shop.owner_id == d.id || shop.pivot.user_id == d.id
                      )"
                      :key="i"
                    >
                      <router-link
                        :to="`/account/shops/${shop.id}`"
                        class="mr-3"
                      >
                        {{ shop.name.ja }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td>
                  <v-btn
                    small
                    dark
                    class="font-weight-bold"
                    color="#002C81"
                    link
                    :to="`${$route.path}/${d.id}`"
                    >詳細</v-btn
                  >
                  <v-btn
                    small
                    dark
                    class="ml-2 font-weight-bold"
                    color="error"
                    link
                    :href="`${userAppBaseUrl}/emulation?user=${d.id}&email=${d.email}`"
                    target="_blank"
                    >ログイン</v-btn
                  >
                </td>
              </tr>
              <tr v-show="!data.length && !tableLoading">
                <td>該当のアカウントはありません。</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { models } from '@am-area/sdk'
  const { User } = models
  import HeaderOfList from '@/components/HeaderOfList.vue'

  export default {
    components: {
      HeaderOfList,
    },
    data() {
      return {
        data: [],
      }
    },
    props: {
      miniNav: { type: Boolean, default: false },
    },

    computed: {
      userAppBaseUrl() {
        return process.env.VUE_APP_USER_APP_BASE_URL
      },
    },
    async created() {
      await this.getUsers()
    },
    methods: {
      async getUsers() {
        this.tableLoading = true
        const query = _.cloneDeep(this.$route.query)
        const users = await User.index({
          query: query,
          with: 'areas,hotels,shops',
        })
        this.data = users
        this.tableLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
  }
</style>
