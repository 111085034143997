<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          dark
          color="#002C81"
          v-bind="attrs"
          v-on="on"
        >
          <slot />
        </v-btn>
      </template>
      <v-card>
        <v-btn
          @click="dialog = false"
          color="transparent"
          style="position: absolute; right: 16px; top: 16px"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-title><slot /></v-card-title>
        <v-divider></v-divider>
        <v-container>
          <HotelMaster :hotel-name="hotelName" @selectHotel="add" />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="dialog = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HotelMaster from '@/components/parts/HotelMaster.vue'

export default {
  components: {
    HotelMaster,
  },
  props: {
    hotelName: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    add(hotel) {
      this.$emit('selectHotel', hotel.seq)
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
