<template>
  <v-chip
  :x-small="xSmall"
  :small="small"
  outlined
  color="error">
    必須
  </v-chip>
</template>

<script>
export default {

  props: {
    size: String,
  },
  computed: {
    xSmall(){
      return this.size == 'x-small'
    },
    small(){
      if (!this.size) return true
      return this.size == 'small'
    },
  }

}
</script>